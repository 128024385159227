import ParticlesEffect from "../components/ParticlesEffect";

const Home = () => {
  return (
    <>
      <ParticlesEffect className="fixed" />
      <div className="absolute grid place-items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="p-6 hover:cursor-pointer">
          <div style={{"width": "110%", "fontSize":"7vw", "display":"flex"}} className="font-GalatiaSil font-medium  text-9xl text-[#6748f3]">
             <p>ÁGORΛ</p> 
             <span style={{"writing-mode": "vertical-rl", "text-orientation": "upright", "fontSize":"1.3vw"}} className="text-2xl font-bold ">
              <p>corp</p>
             </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
