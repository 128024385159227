import { useCallback } from "react";

import { loadFull } from "tsparticles";

import Particles from "react-particles";

const ParticlesEffect = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <div className="App">
      <Particles options={particlesOptions} init={particlesInit} />
    </div>
  );
};

const particlesOptions = {
  background: {
    color: {
      value: "#000",
    },
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: "grab",
      },
      resize: false,
    },
    modes: {
      grab: {
        distance: 150,
      },
    },
  },
  particles: {
    color: {
      value: "#5C62BF",
    },
    links: {
      color: "#5C62BF",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 3,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      random: true,
      value: 5,
    },
  },
  detectRetina: true,
};

export default ParticlesEffect;
